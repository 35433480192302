import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, Wrapper, SliceZone, SEO, Hero, AuthorCarousel, JesusCarousel } from '../components'
import website from '../../config/website'

const Main = Wrapper.withComponent('main')

const getHeroProperties = (path, data) => {
  const height = data.hero_height || 'auto'

  switch (path) {
    default:
      return { overlay: true, height }
  }
}

const Carousel = ({ path }) => {
  switch (path) {
    case '/who-we-are':
    case '/who-we-are/':
      return <AuthorCarousel />
    case '/jesus':
    case '/jesus/':
      return <JesusCarousel />
    default:
      return null
  }
}

const Page = ({ data: { prismicPage }, location }) => {
  const { data } = prismicPage
  const heroContent = {
    title: data.title.text,
    image: data.hero_image,
    properties: getHeroProperties(location.pathname, data)
  }

  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleShort}`}
        pathname={location.pathname}
        desc={data.description}
        node={prismicPage}
        article
      />

      <Hero {...heroContent} invert />
      <Carousel path={location.pathname} />

      <Main id={website.skipNavId} zones>
        <SliceZone allSlices={data.body} />
      </Main>
    </Layout>
  )
}

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    prismicPage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({}),
        description: PropTypes.string,
        hero_image: PropTypes.shape({})
      }).isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PageTemplateQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        description
        hero_height
        hero_image {
          localFile {
            ...HeroImage
          }
        }
        body {
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyQuote {
            id
            slice_type
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyImage {
            id
            slice_type
            primary {
              image {
                localFile {
                  ...FluidImage
                }
              }
            }
          }
          ... on PrismicPageBodyNode {
            id
            slice_type
            primary {
              node {
                document {
                  data {
                    content {
                      html
                    }
                    title {
                      html
                      text
                    }
                    bible_verses {
                      quote {
                        html
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
